import React from 'react';

import { CheckPermissions } from '@components/UI/CheckPermissions';

import QrScanButton from './QrScanButton';

import './Actions.scss';

const ActionButtons = () => {
  return (
    <div className="flex flex-col p-2 gap-2 action-buttons">
      <CheckPermissions permit={{ appointments: ['canCreate', 'canDelete'] }}>
        <QrScanButton />
      </CheckPermissions>
    </div>
  );
};

export default ActionButtons;
