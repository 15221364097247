import { FetchService } from '@modules/Api';

export class BiomaterialScheduleExceptionsApi extends FetchService {
  public getExceptionsList = (query?: GetBiomaterialScheduleExceptions) => {
    return this.request<
      'BIOMATERIAL_SCHEDULE_EXCEPTIONS',
      BiomaterialScheduleException[],
      null,
      GetBiomaterialScheduleExceptions
    >(
      '/biomaterial_type_not_working_day',
      'GET_BIOMATERIALS_SCHEDULES_EXCEPTIONS',
      {
        query,
      },
    );
  };

  public createException = (params: CreateBiomaterialScheduleException) => {
    return this.request<
      'BIOMATERIAL_SCHEDULE_EXCEPTIONS',
      BiomaterialScheduleException[]
    >(
      '/biomaterial_type_not_working_day',
      'CREATE_BIOMATERIAL_SCHEDULE_EXCEPTIONS',
      {
        method: 'POST',
        params,
      },
    );
  };

  // id = '1,2,3...'
  public deleteException = (id: string) => {
    return this.request<'BIOMATERIAL_SCHEDULE_EXCEPTIONS'>(
      '/biomaterial_type_not_working_day',
      'DELETE_BIOMATERIAL_SCHEDULE_EXCEPTIONS',
      {
        method: 'DELETE',
        pathParam: `${id}`,
      },
    );
  };
}
