import { createBrowserHistory, LocationState } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import queryString from 'query-string';
import { RoutingStore } from './routing.store';

class RoutingService extends RoutingStore {
  constructor() {
    super();
    this._preloadRootPages();
  }

  // TODO: args to object & refactoring
  public goToRoute = <T extends RoutePath>(
    link: T,
    options?: BaseObject | null,
    query?: Record<string, unknown> | null,
    state?: LocationState,
    blank?: boolean,
    hash?: T extends keyof RouteHashes ? RouteHashes[T] : null,
  ) => {
    let path = link as unknown as string;
    if (options) {
      path = Object.entries(options).reduce(
        (prev, [optionKey, optionValue]) =>
          prev.replace(`:${optionKey}`, optionValue as string),
        path,
      );
    }
    if (query) {
      const strQuery = this._getQueryString(query);
      path = strQuery ? `${path}?${strQuery}` : link;
    }
    if (hash) {
      path += `#${hash}`;
    }
    if (blank) {
      const win = window.open(path, '_blank');
      win?.focus();
      return;
    }
    this.history.push(path, state);
  };

  public goToExternalRoute = <Q extends Record<string, string | number>>(
    link: ExternalRoutesKeys,
    config: ExternalRoutesOptions<Q> = {},
  ) => {
    const { query = {}, target } = config;
    const strQuery = this._getQueryString(query);
    const url = strQuery ? `${link}?${strQuery}` : link;
    window.open(url, target);
  };

  public removeQuery = (...keys: string[]) => {
    let query = '' as unknown as Record<string, any>;
    if (keys.length) {
      query = this.getQuery();
      keys.forEach((key) => (query[key] = undefined));
    }
    const search = queryString.stringify(query);
    this.push({ search });
  };

  public addQuery = (query: Params, hash?: WorkplaceRouteHashes) => {
    const search = queryString.stringify({
      ...this.getQuery(),
      ...query,
    });

    this.push({
      ...this.location,
      search,
      hash: hash ? `#${hash}` : this.location.hash,
    });
  };

  public changeQuery = <K extends QueryRouterKeys>(
    key: K,
    value: QueryRoutes[K][number],
  ) => {
    const search = queryString.stringify({
      ...this.getQuery(),
      [key]: value,
    });

    this.push({ ...this.location, search });
  };

  public getQuery = <
    Params extends { [K in keyof Params]?: string } = Record<string, never>,
  >(): Params => {
    return queryString.parse(this.location.search) as Params;
  };

  public getHash = () => {
    return history.location.hash.replace('#', '') as WorkplaceRouteHashes;
  };

  private _getQueryString = <Q extends Record<string, unknown>>(query: Q) => {
    const strQuery = queryString.stringify(query);
    if (strQuery) return strQuery;
    return '';
  };

  private _preloadRootPages() {
    const rootRoutes = this.config?.getRouteBy({
      field: 'group',
      value: 'ROOT',
    });
    const needPreload = this.config?.getRouteBy({
      field: 'preload',
      value: true,
      routes: rootRoutes,
    });
    if (needPreload && needPreload.length) {
      needPreload?.map((e) => e.component.preload());
    }
  }

  public clearState = () => {
    this.history.replace({ ...this.location, state: {} });
  };
}

const routingService = new RoutingService();

export const history = syncHistoryWithStore(
  createBrowserHistory(),
  routingService,
);

export default routingService;
