import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDataReport = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      fill="none"
      viewBox="0 0 200 200"
      role="img"
      {...props}
    >
      <path
        fill="#3B82F6"
        d="M146.863 28.183C126.305 13.42 91.212 9.601 72.905 15.773c-33.015 11.14-38.31 32.787-37.952 52.14.357 19.353 25.163 35.972 31.766 52.889 6.603 16.916 18.712 39.656 42.098 32.692 23.385-6.965 22.383-32.146 29.468-49.148S163.9 69.91 162.893 54.303c-.702-10.9-6.947-19.572-16.03-26.12"
      />
      <path
        fill="#fff"
        d="M146.863 28.183C126.305 13.42 91.212 9.601 72.905 15.773c-33.015 11.14-38.31 32.787-37.952 52.14.357 19.353 25.163 35.972 31.766 52.889 6.603 16.916 18.712 39.656 42.098 32.692 23.385-6.965 22.383-32.146 29.468-49.148S163.9 69.91 162.893 54.303c-.702-10.9-6.947-19.572-16.03-26.12"
        opacity={0.7}
      />
      <path
        fill="#000"
        d="M90.811 42.694H34.264a3.68 3.68 0 0 0-3.68 3.68V87.18a3.68 3.68 0 0 0 3.68 3.68h56.547a3.68 3.68 0 0 0 3.68-3.68V46.374a3.68 3.68 0 0 0-3.68-3.68"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M89.227 40.158H32.68a3.68 3.68 0 0 0-3.68 3.68v40.807a3.68 3.68 0 0 0 3.68 3.68h56.547a3.68 3.68 0 0 0 3.68-3.68V43.839a3.68 3.68 0 0 0-3.68-3.68"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M49.661 62.189 36 66.622a14.377 14.377 0 0 1 9.229-18.113 13.7 13.7 0 0 1 4.433-.706z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m49.661 62.189 8.45 11.621a14.373 14.373 0 0 1-20.072-3.18A13.7 13.7 0 0 1 36 66.621z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m49.661 62.188 13.67-4.442A14.15 14.15 0 0 1 58.12 73.81z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M49.661 62.188V47.812a14.15 14.15 0 0 1 13.67 9.934z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M49.683 71.142a8.962 8.962 0 1 0 0-17.924 8.962 8.962 0 0 0 0 17.924"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M87.777 46.934H68.084v1.631h19.693z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M83.541 50.424H68.084v1.632h15.457z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M79.443 53.915H68.084v1.632h11.36z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M84.226 57.406H68.084v1.632h16.141z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M75.754 60.897h-7.67v1.631h7.67z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M87.777 64.392H68.084v1.632h19.693z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M82.586 67.883H68.084v1.631h14.502z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M80.756 71.374H68.084v1.631h12.672z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M87.777 74.865H68.084v1.631h19.693z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M42.352 79.621h15.264M40.191 81.773h19.585M68.609 79.621h15.264M66.448 81.773h19.585"
      />
      <path
        fill="#000"
        d="M91.126 110.949H42.563a3.68 3.68 0 0 0-3.68 3.68v34.788a3.68 3.68 0 0 0 3.68 3.681h48.563a3.68 3.68 0 0 0 3.68-3.681v-34.788a3.68 3.68 0 0 0-3.68-3.68"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M89.223 108.736H40.661a3.68 3.68 0 0 0-3.68 3.681v34.788a3.68 3.68 0 0 0 3.68 3.68h48.562a3.68 3.68 0 0 0 3.68-3.68v-34.788a3.68 3.68 0 0 0-3.68-3.681"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M40.812 128.773h7.46M40.812 130.461H52.61M40.812 132.144h7.46M40.812 133.827h10.76M40.812 135.514h7.46M40.812 137.197h13.18M59.492 128.773h7.46M59.492 130.461h11.799M59.492 132.144h7.46M59.492 133.827h10.762M59.492 135.514h7.46M59.492 137.197h13.18M78.174 128.773h7.46M78.174 130.461h11.794M78.174 132.144h7.46M78.174 133.827H88.93M78.174 135.514h7.46M78.174 137.197h13.18"
      />
      <path
        fill="#1E293B"
        d="M54.77 123.238H40.334v2.888h14.438zM73.779 123.238H59.342v2.888h14.437zM92.787 123.238H78.35v2.888h14.437z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M57.022 123.07v14.614M75.875 123.07v14.614"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="m46.218 116.394 3.124-1.016a3.283 3.283 0 1 1-3.124-2.268z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M46.218 116.394v-3.284a3.23 3.23 0 0 1 3.125 2.268z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="m77.855 116.394 3.125-1.016a3.285 3.285 0 1 1-3.125-2.268z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M77.855 116.394v-3.284a3.22 3.22 0 0 1 3.125 2.268z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M56.772 116.394v3.284a3.284 3.284 0 1 1 0-6.568z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M56.772 116.394v-3.284a3.284 3.284 0 1 1 0 6.568z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="m67.31 116.394 3.124 1.016a3.286 3.286 0 0 1-6.25-2.032 3.23 3.23 0 0 1 3.125-2.268z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M67.31 116.394v-3.284a3.3 3.3 0 0 1 2.324.96 3.28 3.28 0 0 1 .8 3.34z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="m88.401 116.394 3.125 1.016a3.286 3.286 0 0 1-6.25-2.032 3.23 3.23 0 0 1 3.125-2.268z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M88.4 116.394v-3.284a3.29 3.29 0 0 1 2.325.96 3.29 3.29 0 0 1 .8 3.34z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M89.047 146.21H86.17v.973h2.876z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M85.853 141.351h-2.875v5.832h2.875z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M82.663 143.447h-2.875v3.741h2.875z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M79.47 140.529h-2.876v6.654h2.875z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M76.276 145.466H73.4v1.722h2.875z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M73.082 144.941h-2.876v2.242h2.876z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M69.887 142.698h-2.875v4.49h2.875z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M66.694 146.439h-2.876v.749h2.876z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M63.5 141.351h-2.876v5.832H63.5z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M60.306 142.474H57.43v4.713h2.875z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M57.116 146.959h-2.875v.224h2.875z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M53.923 146.886h-2.876v.297h2.876z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M50.729 143.447h-2.876v3.741h2.876z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M47.535 146.439h-2.876v.749h2.876z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M44.34 145.69h-2.874v1.498h2.875z"
      />
      <path
        fill="#000"
        d="M166.72 74.456h-56.547a3.68 3.68 0 0 0-3.681 3.68v40.806a3.68 3.68 0 0 0 3.681 3.68h56.547a3.68 3.68 0 0 0 3.68-3.68V78.136a3.68 3.68 0 0 0-3.68-3.68"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M164.503 71.925h-56.547a3.68 3.68 0 0 0-3.68 3.68v40.806a3.68 3.68 0 0 0 3.68 3.68h56.547a3.68 3.68 0 0 0 3.68-3.68V75.605a3.68 3.68 0 0 0-3.68-3.68"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M164.159 109.61h-3.315v3.676h3.315z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M160.474 91.23h-3.314v22.052h3.314z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M156.789 99.15h-3.314v14.136h3.314z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M153.109 88.123h-3.314v25.163h3.314z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M149.425 106.782h-3.315v6.504h3.315z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M145.74 104.802h-3.314v8.48h3.314z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M142.06 96.322h-3.315v16.964h3.315z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M138.375 110.458h-3.314v2.828h3.314z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M134.691 91.23h-3.315v22.052h3.315z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M131.01 95.474h-3.314v17.812h3.314z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M127.326 112.434h-3.315v.848h3.315z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M123.641 112.154h-3.314v1.132h3.314z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M119.957 99.15h-3.315v14.136h3.315z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M116.276 110.458h-3.314v2.828h3.314z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.323}
        d="M112.592 107.63h-3.315v5.656h3.315z"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.43}
        d="M164.374 81.989h-54.666v2.798h54.666z"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.43}
        d="M132.977 82.19h-8.867v2.39h8.867z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.43}
        d="M149.988 82.19h-16.654v2.39h16.654z"
      />
      <path
        fill="#000"
        d="M159.747 22.42h-44.353a3.68 3.68 0 0 0-3.68 3.68v31.616a3.68 3.68 0 0 0 3.68 3.68h44.353a3.68 3.68 0 0 0 3.68-3.68V26.1a3.68 3.68 0 0 0-3.68-3.68"
        opacity={0.2}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M157.844 19.889h-44.353a3.68 3.68 0 0 0-3.68 3.68v31.616a3.68 3.68 0 0 0 3.68 3.68h44.353a3.68 3.68 0 0 0 3.68-3.68V23.57a3.68 3.68 0 0 0-3.68-3.68"
      />
      <path
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.215}
        d="M112.213 55.215h48.554M112.213 55.215v.72M115.45 55.215v.72M118.687 55.215v.72M121.924 55.215v.72M125.161 55.215v.72M128.397 55.215v.72M131.635 55.215v.72M134.871 55.215v.72M138.109 55.215v.72M141.345 55.215v.72M144.582 55.215v.72M147.819 55.215v.72M151.056 55.215v.72M154.293 55.215v.72M157.53 55.215v.72M160.767 55.215v.72"
      />
      <path
        fill="#3B82F6"
        d="M113.831 54.63h45.317v-6.263l-3.206-6.198a.5.5 0 0 1-.044-.138l-2.849-18.384a.343.343 0 0 0-.344-.345.345.345 0 0 0-.345.345l-2.901 22.443a.43.43 0 0 1-.151.275l-2.793 2.152a.328.328 0 0 1-.499-.142L143.2 42.57a.321.321 0 0 0-.563-.073l-2.91 3.766-3.237 4.175-3.237-4.175-2.854-3.68a.327.327 0 0 0-.585.129l-3.013 9.396-3.237-16.748-3.237 18.419-3.237-2.506-2.583-7.274c-.146-.43-.671-.293-.671.155z"
      />
      <path
        fill="#1E293B"
        d="M159.148 54.643h-45.317v-5.368l3.203-5.329a.3.3 0 0 0 .043-.12l2.849-15.81a.36.36 0 0 1 .347-.286.35.35 0 0 1 .346.286l2.897 19.3a.37.37 0 0 0 .155.237l2.794 1.86a.35.35 0 0 0 .405-.012.34.34 0 0 0 .094-.109l2.811-4.993a.35.35 0 0 1 .564-.06l2.914 3.228 3.237 3.59 3.237-3.59 2.849-3.163a.355.355 0 0 1 .487-.033.36.36 0 0 1 .103.145l3.013 8.079h3.237l3.237 1.438 3.237-2.153 2.583-6.254a.347.347 0 0 1 .601-.096.35.35 0 0 1 .074.23z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M138.371 25.084H148.4M138.371 27.435H148.4M138.371 29.78H148.4M138.371 32.13H148.4M138.371 34.477H148.4M138.371 36.827H148.4M136.09 25.084h1.256M136.09 27.435h1.256M136.09 29.78h1.256M136.09 32.13h1.256M136.09 34.477h1.256M136.09 36.827h1.256"
      />
      <path
        fill="#3B82F6"
        d="M98.947 185.294c14.13 0 25.585-5.685 25.585-12.698 0-7.012-11.455-12.698-25.585-12.698s-25.586 5.686-25.586 12.698 11.455 12.698 25.586 12.698"
      />
      <path
        fill="#fff"
        d="M98.947 185.294c14.13 0 25.585-5.685 25.585-12.698 0-7.012-11.455-12.698-25.585-12.698s-25.586 5.686-25.586 12.698 11.455 12.698 25.586 12.698"
        opacity={0.5}
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M90.351 76.875s-6.568 23.429-6.568 25.232c0 1.537 2.152 4.606 4.528 3.375s2.914-5.062 2.914-5.062zM107.922 172.846s-3.362 4.541-3.616 6.457 2.346 2.066 3.491 1.175 3.529-6.702 3.529-6.702zM89.576 161.913s-5.755 2.419-5.755 3.801 2.992 1.033 5.166.228c2.173-.805 5.638-2.187 5.638-2.187s-3.323-2.186-5.049-1.842"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m91.38 107.247-.194 7.481s-1.494 13.697-2.187 21.066 1.498 24.285 1.498 24.285l-1.037 1.033v1.038l5.165 1.609a23 23 0 0 0 .46-3.224v-1.721l5.411-32.339s4.257 32.687 4.95 36.484c.693 3.796 1.722 7.825 1.722 7.825l-.344 1.15.456 2.074 3.568.921c.604-.931.96-2 1.038-3.107.228-2.075.344-6.909.344-6.909s-.228-16.787.577-28.194c.479-7.015.402-14.058-.232-21.061l-.573-7.593a28.28 28.28 0 0 1-20.622-.818"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m100.509 126.466-.576-4.257-2.299-2.415M108.567 111.392s-1.614 2.992 2.759 4.373M111.326 117.147v53.168M96.825 112.081l.46 2.763s-1.149 2.07-.688 2.992"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M99.132 73.888s-8.63 2.526-8.781 2.987c-.15.46 1.029 30.372 1.029 30.372s2.302 3.529 8.608 4.068 12.277-2.764 12.277-2.764l4.115-27.234s-9.901-6.754-10.589-6.908c-.689-.156-4.739-1.06-6.66-.521"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m98.538 79.638 2.049 5.028 3.503-3.969s-.262-2.514-2.582-2.776a2.71 2.71 0 0 0-2.97 1.717"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m99.132 73.888-3.637 5.75s2.117-.53 3.108.332c.99.86 2.117 1.786 2.117 1.786s1.722-1.39 3.108-.925c.949.349 1.84.84 2.643 1.454 0 0 .861-5.686-.263-8.264-1.123-2.578-4.696-3.508-7.076-.133"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M100.057 74.546a47 47 0 0 0-.064 5.028c.129 2.118.792 2.582 1.584 1.192s2.583-5.622 2.583-6.814q.003-1.126.129-2.247s-2.777 2.966-4.232 2.841"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m100.587 84.666-3.44 17.523 2.316 5.82 2.846-4.761z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M107.34 100.648s-7.748-4.679-8.436-5.29c-.689-.611-.693-.155-1.231.15-.538.306-2.531 2.687-2.914 3.444s-1 1.843-.229 1.998c.77.154 1.382-1.292 1.382-1.537s1.15-1.532 1.15-1.532-1.15 2.759-.767 2.991 2.992 1.291 3.53 1.291.917-.998.917-.998.77.998 1.536.921a3 3 0 0 0 1.292-.461l2.608 2.071z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M107.34 100.648s-.964-.581-2.238-1.36l-1.136 2.665 2.225 1.764z"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M97.286 101.419s.232-2.458 1.23-3.84M98.59 101.724s.537-2.152 1.614-3.374"
      />
      <path
        fill="#fff"
        d="M113.169 82.699a11.4 11.4 0 0 0-3.607 6.211l4.834 13.74s-5.755-2.381-6.457-2.454-3.147 3.297-2.454 4.064 14.205 8.363 15.036 7.364-3.444-28.155-3.913-29.153c-.469-.999-.151-1.533-1.765-1.765"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M113.169 82.699a11.4 11.4 0 0 0-3.607 6.211l4.834 13.74s-5.755-2.381-6.457-2.454-3.147 3.297-2.454 4.064 14.205 8.363 15.036 7.364-3.444-28.155-3.913-29.153c-.469-.999-.151-1.533-1.765-1.765"
      />
      <path fill="#fff" d="M114.396 102.65s2.763.615 3.529 2.07Z" />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M114.396 102.65s2.763.615 3.529 2.07"
      />
      <path
        fill="#3B82F6"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m83.507 91.333 6.164 13.774a.62.62 0 0 0 .314.306l8.82 3.616a.591.591 0 0 0 .787-.741l-4.937-13.343a1.72 1.72 0 0 0-1.024-1.02l-9.375-3.397a.59.59 0 0 0-.75.805"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M85.818 98.677a.564.564 0 0 0-.675.602.76.76 0 0 0 .21.534c.34.241 2.893.431 2.794-.052-.065-.374-1.382-.9-2.329-1.084"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M85.022 97.747a.672.672 0 0 0-.805.715.9.9 0 0 0 .254.632c.4.284 3.444.513 3.314-.06-.09-.443-1.653-1.067-2.763-1.287"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M84.781 96.378a.732.732 0 0 0-.86.78.98.98 0 0 0 .275.688c.43.31 3.727.56 3.603-.06-.108-.487-1.808-1.162-3.018-1.408"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="M84.286 95.074a.732.732 0 0 0-.861.779.94.94 0 0 0 .276.689c.43.31 3.727.56 3.602-.06-.112-.487-1.808-1.15-3.017-1.408"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.43}
        d="M95.86 64.599s.517 5.268 1.67 7.709 1.542 3.594 2.699 3.594c1.158 0 3.341-1.67 4.365-3.21a25 25 0 0 0 1.416-2.312l.254-.642s1.799-1.291 2.187-2.182-.388-1.541-1.799-.77c0 0 .512-3.982-.517-6.423s-8.858-.129-10.275 4.236Z"
      />
      <path
        fill="#fff"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m94.462 66.6 8.677 1.77s.061 1.347-.861 1.773a2.62 2.62 0 0 1-2.75-.43c-.672-.612-.672-1.774-.672-1.774s-.55-.43-.61 0a1.657 1.657 0 0 1-1.697 1.403 2.04 2.04 0 0 1-1.825-1.36 5.2 5.2 0 0 1-.263-1.382"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m103.139 68.37 3.607-1.894M94.462 66.6l1.403-.55"
      />
      <path
        fill="#fff"
        d="m98.31 68.31-.611 2.263.736.181.18 1.773 1.163-.305"
      />
      <path
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.43}
        d="m98.31 68.31-.611 2.263.736.181.18 1.773 1.163-.305"
      />
      <path
        fill="#1E293B"
        stroke="#1E293B"
        strokeMiterlimit={10}
        strokeWidth={0.43}
        d="M99.593 56.123s-5.14 3.982-5.652 6.423 2.824 3.469 5.01 3.082c2.187-.388 5.376-2.441 5.376-2.441s-1.799 2.828-.387 2.953c.64.092 1.289.092 1.928 0 0 0-.77 1.545-.129 1.545.642 0 .9-.9.9-.9s1.799-4.24 1.291-8.092-5.26-5.01-8.337-2.57Z"
      />
    </svg>
  );
};
export default SvgDataReport;
