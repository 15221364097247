import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEcgHeart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <mask
        id="a"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#6B7280"
          d="M13.438 2.5q2.082 0 3.49 1.542 1.405 1.541 1.405 3.666 0 .375-.041.74a4 4 0 0 1-.146.719h-5.208L11.52 7.042a.8.8 0 0 0-.292-.271.8.8 0 0 0-.885.062.86.86 0 0 0-.302.417l-1.125 3.375-.73-1.083a.8.8 0 0 0-.291-.271.8.8 0 0 0-.396-.104H1.854a4.3 4.3 0 0 1-.146-.72 6 6 0 0 1-.041-.718q0-2.146 1.396-3.687Q4.458 2.5 6.542 2.5q1 0 1.885.396Q9.313 3.292 10 4q.667-.708 1.552-1.104a4.6 4.6 0 0 1 1.886-.396M10 17.5q-.375 0-.719-.135a1.8 1.8 0 0 1-.614-.407l-5.584-5.604a4 4 0 0 1-.437-.52h4.396l1.416 2.124q.105.167.292.271.188.105.396.104.27 0 .5-.166a.83.83 0 0 0 .312-.417l1.125-3.375.709 1.083a1 1 0 0 0 .312.271q.188.105.396.104h4.833l-.416.5-5.604 5.625A1.82 1.82 0 0 1 10 17.5"
        />
      </g>
    </svg>
  );
};
export default SvgEcgHeart;
