import Default from '@components/UI/Modal/components/Default';
import PreloadLazy from '@router/utils/preload-lazy';

export const Components = {
  Default,
  ErrorModal: PreloadLazy(
    () => import('@components/UI/Modal/components/ErrorModal'),
  ),
  GrantingAccessModal: PreloadLazy(
    () => import('@components/UI/Modal/components/GrantingAccessModal'),
  ),
  ChangeUserEmailModal: PreloadLazy(
    () => import('@components/UI/Modal/components/ChangeUserEmail'),
  ),
  CopyPriceModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CopyPriceModal'),
  ),
  CopyPriceCostPercentModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CopyPriceCostPercentModal'),
  ),
  CopyPriceFixedModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CopyPriceFixedModal'),
  ),
  CopyPriceRoundingModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CopyPriceRoundingModal'),
  ),
  PriceCostRemoveModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PriceCostRemoveModal'),
  ),
  PreviewDocChapterModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PreviewDocChapterModal'),
  ),
  PreviewDocTemplateModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PreviewDocTemplateModal'),
  ),
  CloseMedEpisodeModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CloseMedEpisodeModal'),
  ),
  UpdateMedEpisodeModal: PreloadLazy(
    () => import('@components/UI/Modal/components/UpdateMedEpisodeModal'),
  ),
  SelectLabSecondaryModal: PreloadLazy(
    () => import('@components/UI/Modal/components/SelectLabServiceModal'),
  ),
  CreateTemplateModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CreateTemplateModal'),
  ),
  ZReportModal: PreloadLazy(
    () => import('@components/UI/Modal/components/ZReportModal'),
  ),
  CreatePatientModal: PreloadLazy(
    () => import('@components/UI/Modal/components/CreatePatientModal'),
  ),
  UpdatePatientCreditTrustModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/UpdatePatientCreditTrustModal'),
  ),
  PrintOrderModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PrintOrderModal'),
  ),
  PrintBarCodesModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PrintBarCodesModal'),
  ),
  AppointmentProgressModal: PreloadLazy(
    () => import('@components/UI/Modal/components/AppointmentProgressModal'),
  ),
  SelectLabServiceErrorModal: PreloadLazy(
    () => import('@components/UI/Modal/components/SelectLabServiceErrorModal'),
  ),
  UpdateDocChapterNameModal: PreloadLazy(
    () => import('@components/UI/Modal/components/UpdateDocChapterNameModal'),
  ),
  PayOrderConfirmationModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PayOrderConfirmationModal'),
  ),
  RedirectBiomaterialModal: PreloadLazy(
    () => import('@components/UI/Modal/components/RedirectBiomaterialModal'),
  ),
  InvalidBiomaterialModal: PreloadLazy(
    () => import('@components/UI/Modal/components/InvalidBiomaterialModal'),
  ),
  PrintBioBarCodeModal: PreloadLazy(
    () => import('@components/UI/Modal/components/PrintBioBarCodeModal'),
  ),
  LabIncorrectTestModal: PreloadLazy(
    () => import('@components/UI/Modal/components/LabIncorrectTestModal'),
  ),
  BiomaterialServiceUploadFileModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/BiomaterialServiceUploadFileModal'
      ),
  ),
  NoPatientEmailModal: PreloadLazy(
    () => import('@components/UI/Modal/components/NoPatientEmailModal'),
  ),
  AddBioPregnancyPeriodModal: PreloadLazy(
    () => import('@components/UI/Modal/components/AddBioPregnancyPeriodModal'),
  ),
  RemoveOrderServiceModal: PreloadLazy(
    () => import('@components/UI/Modal/components/RemoveOrderServiceModal'),
  ),
  FinishAppointmentModal: PreloadLazy(
    () => import('@components/UI/Modal/components/FinishAppointmentModal'),
  ),
  DownloadPrinterModal: PreloadLazy(
    () => import('@components/UI/Modal/components/DownloadPrinterModal'),
  ),
  DownloadAnalyserModal: PreloadLazy(
    () => import('@components/UI/Modal/components/DownloadAnalyserModal'),
  ),
  AddRemedAccountModal: PreloadLazy(
    () => import('@components/UI/Modal/components/AddRemedAccountModal'),
  ),
  AddPatientRemedAccount: PreloadLazy(
    () => import('@components/UI/Modal/components/AddPatientRemedAccount'),
  ),
  AddCommentToDirectionModal: PreloadLazy(
    () => import('@components/UI/Modal/components/AddCommentToDirectionModal'),
  ),
  ActivationCounterpartyModal: PreloadLazy(
    () => import('@components/UI/Modal/components/ActivationCounterpartyModal'),
  ),
  OnReFinalizeCommentModal: PreloadLazy(
    () => import('@components/UI/Modal/components/OnReFinalizeCommentModal'),
  ),
  UpdateConfirmedScheduleStaffModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/UpdateConfirmedScheduleStaffModal'
      ),
  ),
  OnDeleteScheduleEventModal: PreloadLazy(
    () => import('@components/UI/Modal/components/OnDeleteScheduleEventModal'),
  ),
  CreateExternalDirectionModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/CreateExternalDirectionModal'),
  ),
  DocRefinalizationModal: PreloadLazy(
    () => import('@components/UI/Modal/components/DocRefinalizationModal'),
  ),
  UpdatePackageServicePriceModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/UpdatePackageServicePriceModal'),
  ),
  UpdatePackageServiceLimitModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/UpdatePackageServiceLimitModal'),
  ),
  UpdatePackageServiceCountModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/UpdatePackageServiceCountModal'),
  ),
  EditDateTimeScheduledCallModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/EditDateTimeScheduledCallModal'),
  ),
  SelectBiomaterialCabinetModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/SelectBiomaterialCabinetModal'),
  ),
  SendingBiomaterialErrorModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/SendingBiomaterialErrorModal'),
  ),
  ServiceInPackageCalendarModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/ServiceInPackageModal/ServiceInPackageCalendarModal'
      ),
  ),
  ServiceInPackageAnalysesModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/ServiceInPackageModal/ServiceInPackageAnalysesModal'
      ),
  ),
  ServiceInPackageWorkplaceModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/ServiceInPackageModal/ServiceInPackageWorkplaceModal'
      ),
  ),
  ScanQrModal: PreloadLazy(
    () => import('@components/UI/Modal/components/ScanQrModal/ScanQrModal'),
  ),
  QrCodeInformationModal: PreloadLazy(
    () =>
      import(
        '@components/UI/Modal/components/ScanQrModal/QrCodeInformationModal'
      ),
  ),
  QrCodeErrorMessageModal: PreloadLazy(
    () =>
      import('@components/UI/Modal/components/ScanQrModal/QrCodeErrorMessage'),
  ),
};
