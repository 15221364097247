import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/UI/Button';
import { useService } from '@stores/hooks';

const QRScanButton: React.FC = () => {
  const { t } = useTranslation('layout');

  const { modal } = useService('ModalService');

  const handleQrScanModalOpen = () => {
    modal.scanQr();
  };

  return (
    <Button
      icon="QrCode"
      type="black"
      className="w-full qr-scan-button"
      size="mini"
      onClick={handleQrScanModalOpen}
    >
      {t('menu.scan_qr')}
    </Button>
  );
};

export default QRScanButton;
