import * as React from 'react';
import type { SVGProps } from 'react';
const SvgProfile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      role="img"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#6B7280"
          d="M2.5 4.167v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667H4.167c-.925 0-1.667.75-1.667 1.667m10 3.333c0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5C7.5 6.117 8.617 5 10 5s2.5 1.117 2.5 2.5M5 14.167c0-1.667 3.333-2.584 5-2.584s5 .917 5 2.584V15H5z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgProfile;
