import { FetchService } from '@modules/Api';

export class BiomaterialTypeScheduleApi extends FetchService {
  public getScheduleList = (query?: GetBiomaterialTypeScheduleList) => {
    return this.request<
      'BIOMATERIAL_TYPE_AVAILABILITY_SCHEDULE',
      BiomaterialTypeSchedule[],
      null,
      GetBiomaterialTypeScheduleList
    >(
      '/biomaterial_type_availability_schedule',
      'GET_BIOMATERIAL_TYPE_SCHEDULE_LIST',
      {
        query,
      },
    );
  };

  public createSchedule = (params: CreateScheduleParams) => {
    return this.request<
      'BIOMATERIAL_TYPE_AVAILABILITY_SCHEDULE',
      BiomaterialTypeSchedule[]
    >(
      '/biomaterial_type_availability_schedule',
      'CREATE_BIOMATERIAL_TYPE_SCHEDULE',
      {
        method: 'POST',
        params,
      },
    );
  };

  public updateSchedule = (id: number, params: UpdateScheduleParams) => {
    return this.request<
      'BIOMATERIAL_TYPE_AVAILABILITY_SCHEDULE',
      BiomaterialTypeSchedule
    >(
      '/biomaterial_type_availability_schedule',
      'UPDATE_BIOMATERIAL_TYPE_SCHEDULE',
      {
        method: 'PUT',
        pathParam: `${id}`,
        params,
      },
    );
  };

  public deleteSchedules = (ids: string) => {
    return this.request<'BIOMATERIAL_TYPE_AVAILABILITY_SCHEDULE'>(
      '/biomaterial_type_availability_schedule',
      'DELETE_BIOMATERIAL_TYPE_SCHEDULE_LIST',
      {
        method: 'DELETE',
        pathParam: ids,
      },
    );
  };
}
