import { computed, makeObservable, runInAction } from 'mobx';
import ModalStore from '@modules/Modal/modal.store';
import { modalComponent } from '@modules/Modal/registeration';
import { DocChapterItem } from '@modules/Documents/DocChapter';
import { EnvService } from '@modules/Env';

class ModalService extends ModalStore {
  constructor() {
    super();
    makeObservable(this);
  }

  @computed
  public get modal() {
    const actions: ModalActions = {
      close: this.close,
      setLoading: this.setLoading,
      setDisable: this.setDisable,
      setDisableClose: (val) => {
        runInAction(() => this.setDisableClose(val));
      },
    };

    return {
      config: this,
      actions,
      default: (props: ModalDefaultProps) => {
        this._open(modalComponent.default(props, actions));
      },
      customError: (props: ModalErrorProps) => {
        this._open(modalComponent.error(props, actions));
      },
      error: (errors: ServerError[] = []) => {
        const title = errors[0]?.title;
        const message = errors[0]?.detail;
        if (!EnvService.isDevApp && title?.includes('DEFAULT_ERROR_MESSAGE')) {
          console.log(errors);
        } else if (title) {
          this._open(modalComponent.error({ title, message }, actions));
        }
      },

      wsError: (errors: WsError[]) => {
        if (!errors?.length) {
          this._open(
            modalComponent.error(
              { title: 'WS ERROR', message: JSON.stringify(errors) },
              actions,
            ),
          );
          console.log(errors);
          return;
        }
        const { message: title, code } = errors[0];
        this._open(
          modalComponent.error({ title, message: `Code:${code}` }, actions),
        );
      },
      confirm: (props: ModalConfirmProps) => {
        this._open(modalComponent.confirm(props, actions));
      },
      grantingAccess: (props: ModalGrantingAccessProps) => {
        this._open(modalComponent.grantingAccess(props, actions));
      },
      changeUserEmail: () => {
        this._open(modalComponent.changeUserEmail(actions));
      },
      copyPriceCost: (props: DrawerPriceParams) => {
        this._open(modalComponent.copyPriceCost(props, actions));
      },
      copyPriceCostPercent: (props: DrawerPriceParams) => {
        this._open(modalComponent.copyPriceCostPercent(props, actions));
      },
      copyPriceCostFixed: (props: DrawerPriceParams) => {
        this._open(modalComponent.copyPriceCostFixed(props, actions));
      },
      copyPriceCostRounding: (props: DrawerPriceParams) => {
        this._open(modalComponent.copyPriceCostRounding(props, actions));
      },
      priceCostRemove: (props: DrawerPriceParams) => {
        this._open(modalComponent.priceCostRemove(props, actions));
      },
      previewDocChapter: () => {
        this._open(modalComponent.previewDocChapter(actions));
      },
      previewDocTemplate: () => {
        this._open(modalComponent.previewDocTemplate(actions));
      },
      closeMedEpisode: () => {
        this._open(modalComponent.closeMedEpisode(actions));
      },
      updateMedEpisode: (field: 'title' | 'doctor') => {
        this._open(modalComponent.updateMedEpisode(actions, field));
      },
      selectLabServices: (services: ModalSelectLabSecondaryProps) => {
        this._open(modalComponent.selectLabServices(services, actions));
      },
      selectLabServiceError: (props?: ModalSelectLabServiceErrorProps) => {
        this._open(modalComponent.selectLabServiceError(actions, props));
      },
      createTemplate: (props: ModalCreateTemplate) => {
        this._open(modalComponent.createTemplate(props, actions));
      },
      zReport: (data: ModalZReportProps) => {
        this._open(modalComponent.zReport(actions, data));
      },
      createPatient: (props: ModalCreatePatientProps) => {
        this._open(modalComponent.createPatient(actions, props));
      },
      updatePatientCreditTrust: () => {
        this._open(modalComponent.updatePatientCreditTrust(actions));
      },
      printOrder: (data: ModalPrintOrderProps) => {
        this._open(modalComponent.printOrder(actions, data));
      },
      printBarCodes: (data: ModalPrintBarCodesProps) => {
        this._open(modalComponent.printBarCodes(actions, data));
      },
      appointmentProgress: (props: ModalAppointmentProgressProps) => {
        this._open(modalComponent.appointmentProgress(actions, props));
      },
      updateDocChapterName: (data: DocChapterItem) => {
        this._open(modalComponent.updateDocChapterName(actions, data));
      },
      payOrderConfirmation: (data: ModalPayOrderConfirmation) => {
        this._open(modalComponent.payModalConfirmation(actions, data));
      },
      redirectBiomaterial: (data: ModalRedirectBiomaterialProps) => {
        this._open(modalComponent.redirectBiomaterial(actions, data));
      },
      invalidBiomaterial: (data: ModalInvalidBiomaterialProps) => {
        this._open(modalComponent.invalidBiomaterial(actions, data));
      },
      printBioBarCodes: (data: ModalPrintBioBarCodesProps) => {
        this._open(modalComponent.printBioBarCodes(actions, data));
      },
      labIncorrectTest: () => {
        this._open(modalComponent.labIncorrectTest(actions));
      },
      biomaterialServiceUploadFile: (data: BioWorkplace.BioService) => {
        this._open(modalComponent.biomaterialServiceUploadFile(actions, data));
      },
      noPatientEmail: (patientId: number) => {
        this._open(modalComponent.noPatientEmail(actions, patientId));
      },
      addBioPregnancyPeriod: () => {
        this._open(modalComponent.addBioPregnancyPeriod(actions));
      },
      removeOrderService: (data: ModalRemoveOrderServiceProps) => {
        this._open(modalComponent.removeOrderService(actions, data));
      },
      finishAppointment: (data: ModalFinishAppointmentProps) => {
        this._open(modalComponent.finishAppointmentModal(actions, data));
      },
      downloadPrinter: (props: ModalDownloadPrinterProps) => {
        this._open(modalComponent.downloadPrinter(actions, props));
      },
      downloadAnalyser: (props: ModalDownloadAnalyserProps) => {
        this._open(modalComponent.downloadAnalyser(actions, props));
      },
      addRemedAccount: () => {
        this._open(modalComponent.addRemedAccount(actions));
      },
      addPatientRemedAccount: () => {
        this._open(modalComponent.addPatientRemedAccount(actions));
      },
      addDirectionComment: (props: ModalAddCommentToDirectionProps) => {
        this._open(modalComponent.addDirectionComment(actions, props));
      },
      activateCounterparty: (props: ModalActivateCounterpartyProps) => {
        this._open(modalComponent.activateCounterparty(actions, props));
      },
      onReFinalizeComment: (props: ModalOnReFinalizeProps) => {
        this._open(modalComponent.onReFinalizeComment(actions, props));
      },
      updateConfirmedScheduleStaff: (
        props: ModalUpdateConfirmedScheduleStaffProps,
      ) => {
        this._open(modalComponent.updateConfirmedScheduleStaff(actions, props));
      },
      onDeleteScheduleEvent: (data: ModalDeleteScheduleEventProps) => {
        this._open(modalComponent.onDeleteScheduleEvent(actions, data));
      },
      createExternalDirection: (props: ModalExternalDirectionProps) => {
        this._open(modalComponent.createExternalDirection(actions, props));
      },
      getDocRefinalization: (props: ModalDocRefinalizationProps) => {
        this._open(modalComponent.getDocRefinalization(actions, props));
      },
      editDateTimeScheduledCall: (data: ScheduledCall) => {
        this._open(modalComponent.editDateTimeScheduledCall(actions, data));
      },
      selectBiomaterialCabinet: (props: ModalSelectBioCabinetProps) => {
        this._open(modalComponent.selectBiomaterialCabinet(actions, props));
      },
      sendingBiomaterialError: (props: ModalSendingBiomaterialErrorProps) => {
        this._open(modalComponent.sendingBiomaterialError(actions, props));
      },
      updatePackageServicePrice: (data: ModalUpdatePackageServicePrice) => {
        this._open(modalComponent.updatePackageServicePrice(actions, data));
      },
      updatePackageServiceLimit: (data?: ModalUpdatePackageServiceLimit) => {
        this._open(modalComponent.updatePackageServiceLimit(actions, data));
      },
      updatePackageServiceCount: (data: ModalUpdatePackageServiceCount) => {
        this._open(modalComponent.updatePackageServiceCount(actions, data));
      },
      serviceInCalendarPackage: (data: ModalServiceInPackageProps) => {
        this._open(modalComponent.serviceInCalendarPackage(actions, data));
      },
      serviceInAnalysesPackage: (data: ModalServiceInLabPackageProps) => {
        this._open(modalComponent.serviceInAnalysesPackage(actions, data));
      },
      serviceInWorkplacePackage: (
        data: ModalServiceInPackageWorkplaceProps,
      ) => {
        this._open(modalComponent.serviceInWorkplacePackage(actions, data));
      },
      scanQr: () => {
        this._open(modalComponent.scanQr(actions));
      },
      qrCodeInformation: (data: ModalQrCodeInformationProps) => {
        this._open(modalComponent.qrCodeInformation(actions, data));
      },
      qrCodeErrorMessage: () => {
        this._open(modalComponent.qrCodeErrorMessage(actions));
      },
    };
  }

  public close = (): void => {
    if (!this.isOpen) return;
    if (this.disableClose) return;
    this.setOpen(false);
    this.modalProps.onClose?.();
  };

  private _open = (options: ModalProps): void => {
    if (this.isOpen) return;
    this.setOpen(true);
    this.setModalProps(options);
  };
}

export default new ModalService();
