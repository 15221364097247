import React, { memo, PropsWithChildren, useMemo } from 'react';
import { useService } from '@stores/hooks';
import PermissionDenied from '@components/UI/PermissionDenied';

type Props = PropsWithChildren<{
  permit: Partial<Record<PermissionEntity, PermissionAccessKeys[]>>;
  show403?: boolean;
  Stub?: () => React.JSX.Element;
}>;

function CheckPermissions({
  permit,
  children,
  Stub,
  show403 = false,
}: Props): React.JSX.Element | null {
  const { userCan } = useService('PermissionsService');

  const hasAccess = useMemo<boolean>(() => {
    const entries = Object.entries(permit);
    return entries.every(([key, keysOfAccess]) => {
      const entityAccessData = userCan(key as PermissionEntity);

      return keysOfAccess.every((keyAccess) => entityAccessData[keyAccess]);
    });
  }, [userCan, permit]);

  if (hasAccess) return <>{children}</>;

  if (Stub) return <Stub />;

  if (show403) return <PermissionDenied />;

  return null;
}

export default memo(CheckPermissions);
