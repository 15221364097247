import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQrCode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      role="img"
      {...props}
    >
      <g fill="#fff" clipPath="url(#a)">
        <path d="M2 7.333h5.333V2H2zm1.333-4H6V6H3.333zM2 14h5.333V8.665H2zm1.333-4H6v2.666H3.333zM8.667 2v5.333H14V2zm4 4H10V3.333h2.667zM14 12.666h-1.333v1.333H14zM10 8.666H8.667v1.333H10zM11.333 10H10v1.333h1.333zM10 11.334H8.667v1.333H10zM11.333 12.666H10v1.333h1.333zM12.667 11.334h-1.334v1.333h1.334zM12.667 8.666h-1.334v1.333h1.334zM14 10h-1.333v1.333H14z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgQrCode;
