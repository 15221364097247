import React from 'react';
import Text from '@components/UI/Text/Text';
import errorForbidden from '@assets/img/preview/403-error-forbidden.png';
import { useTranslation } from 'react-i18next';
import './PermissionDenied.scss';

export default function PermissionDenied() {
  const { t } = useTranslation('layout');
  return (
    <div className="rm-error403" data-testid="permission-error">
      <img src={errorForbidden} alt="403 error forbidden" />
      <Text tag="h2" type="secondary">
        {t('error403.title')}
      </Text>
      <Text tag="h4">{t('error403.description')}</Text>
    </div>
  );
}
