import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import './BorderedTabs.scss';

type Props = {
  className?: string;
  defaultActive?: string;
  onSelect?: (tab: UiTab) => void;
  tabs: UiTab[];
};

function BorderedTabs({ tabs, defaultActive, onSelect, className }: Props) {
  const [active, setActive] = useState(defaultActive || tabs[0].id);

  const activeTab = useMemo(
    () => tabs.find((tab) => tab.id === active) || null,
    [active, tabs],
  );

  function onChange(tab: UiTab) {
    onSelect?.(tab);
    setActive(tab.id);
  }

  return (
    <>
      <ul
        data-testid="bordered-tabs"
        className={cn('bordered-tabs', className)}
      >
        {tabs.map((tab) => (
          <li
            key={tab.label}
            className={cn('bordered-tab', { active: tab.id === active })}
            onClick={() => onChange(tab)}
            data-testid={`tab-${tab.id}`}
          >
            {tab.label}
          </li>
        ))}
      </ul>

      <div className="bordered-tab-content" data-testid="bordered-tab-content">
        {activeTab && (
          <activeTab.component ref={activeTab.ref} {...activeTab.props} />
        )}
      </div>
    </>
  );
}

export default BorderedTabs;
