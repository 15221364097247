import { PreloadLazy } from '@router/utils';

export const LABORATORIES_ROUTES: PrivateRoute = {
  group: 'LAB_MANAGEMENT',
  name: 'lab_management',
  path: '/lab-management',
  exact: false,
  sidebar: true,
  icon: 'OutlineLaboratoryManagement',
  activeIcon: 'LaboratoryManagement',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'lab_management',
      path: '/lab-management',
      exact: true,
      component: PreloadLazy(() => import('@views/LabManagement')),
    },
    {
      group: 'LAB_SERVICES',
      name: 'services',
      path: '/lab-management/service-settings',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/med_services.svg'),
      bgColor: 'bg-blue-50',
      routes: [
        {
          name: 'services',
          path: '/lab-management/service-settings',
          exact: true,
          component: PreloadLazy(() => import('@views/LabManagement/Services')),
        },
        {
          name: 'lab_services',
          path: '/lab-management/service-settings/services',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/LabServices'),
          ),
          imagePath: () => import('@assets/img/preview/laboratories.svg'),
        },
        {
          name: 'lab_services_import',
          path: '/lab-management/service-settings/services/import',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/LabServicesImport'),
          ),
        },
        {
          name: 'lab_service',
          path: '/lab-management/service-settings/services/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/LabService'),
          ),
        },
        {
          name: 'properties',
          path: '/lab-management/service-settings/properties',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/Properties'),
          ),
          imagePath: () => import('@assets/img/preview/science.svg'),
        },
        {
          name: 'primary_services',
          path: '/lab-management/service-settings/primary-services',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/PrimaryServices'),
          ),
          imagePath: () => import('@assets/img/preview/science.svg'),
        },
        {
          name: 'primary_services_import',
          path: '/lab-management/service-settings/primary-services/import',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/PrimaryServicesImport'),
          ),
        },
        {
          name: 'primary_service',
          path: '/lab-management/service-settings/primary-services/:id',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/PrimaryService'),
          ),
        },
        {
          name: 'biomaterial_types',
          path: '/lab-management/service-settings/biomaterials',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/BiomaterialTypes'),
          ),
          imagePath: () => import('@assets/img/preview/biomaterial_types.svg'),
        },
        {
          name: 'consumable',
          path: '/lab-management/service-settings/consumable',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/Consumable'),
          ),
          imagePath: () => import('@assets/img/preview/spreadsheet.svg'),
        },
        {
          name: 'consumable_type',
          path: '/lab-management/service-settings/consumable-type',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/ConsumableType'),
          ),
        },
        {
          name: 'biomaterials_schedules',
          path: '/lab-management/service-settings/biomaterials-schedules',
          exact: true,
          component: PreloadLazy(
            () => import('@views/LabManagement/Services/BiomaterialsSchedules'),
          ),
          imagePath: () => import('@assets/img/preview/schedule.svg'),
        },
        {
          name: 'biomaterials_schedules_exceptions',
          path: '/lab-management/service-settings/biomaterials-schedules/exceptions',
          exact: true,
          hidden: true,
          component: PreloadLazy(
            () =>
              import(
                '@views/LabManagement/Services/BiomaterialsSchedulesExceptions'
              ),
          ),
        },
      ],
    },
  ],
};
